import React, { useState, useEffect, useRef, useContext } from 'react';


import axios from '../services/axios';


import { useParams, useNavigate } from 'react-router-dom';


import Lightbox from 'react-image-lightbox';


import 'react-image-lightbox/style.css';


import Footer from './Footer';


import './ListingDetail.css';


import placeholderImage from '../assets/images/browse-listings-bg.webp';


import ContactForm from './ContactForm';


import logo from '../logo.webp';

import { FaImage } from 'react-icons/fa'; // Import FontAwesome image icon


import { Pie } from 'react-chartjs-2';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


import { Loader } from '@googlemaps/js-api-loader';


import ListingWidget from './ListingWidget';


import AuthContext from '../context/AuthContext';


import { FaArrowLeft, FaShareAlt, FaStar } from 'react-icons/fa';


import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';


import { Autoplay } from 'swiper/modules';


import bedroomIcon from '../assets/icons/bedroom-3.svg';

import bathroomIcon from '../assets/icons/bathroom.svg';



// Import your video and thumbnail


import videoFile from '../assets/videos/video.mp4';


import videoThumbnail from '../assets/images/video.webp';



ChartJS.register(ArcElement, Tooltip, Legend);



const ListingDetail = ({ ml_num: propMlNum, isPopup, closePopup }) => {


  const { ml_num: routeMlNum } = useParams();


  const ml_num = propMlNum || routeMlNum;


  const navigate = useNavigate();



  const [listingData, setListingData] = useState({ currentListing: null, historyListings: [] });


  const [isOpen, setIsOpen] = useState(false);


  const [photoIndex, setPhotoIndex] = useState(0);


  const [coordinates, setCoordinates] = useState(null);


  const mapRef = useRef(null);


  const mapInstance = useRef(null);


  const marker = useRef(null);



  const { user } = useContext(AuthContext);



  const [reviews, setReviews] = useState([]);


  const [overallRating, setOverallRating] = useState(0);



  useEffect(() => {


    const fetchListing = async () => {

      try {

        const apiUrl = `/listing/${ml_num}`;

        const response = await axios.get(apiUrl);

        const listing = response.data.currentListing;

    

        setListingData(response.data);

    

        // Include postal code in geocoding

        const coords = await geocodeAddress(listing.address, listing.postal_code);

        setCoordinates(coords);

    

        // Check if search_type is not 'IDX' and user is not logged in

        if (listing.search_type !== 'IDX' && !user) {

          navigate('/login'); // Redirect to login if not logged in

        } else {

          setListingData(response.data);

          const coords = await geocodeAddress(listing.address, listing.postal_code);

          setCoordinates(coords);

        }

      } catch (error) {

        console.error('Error fetching the listing data', error);

      }

    };

    



    fetchListing();


  }, [ml_num]);



  useEffect(() => {

    if (isOpen) {

      document.body.focus();

    }

  }, [isOpen]);

  


  useEffect(() => {


    const loader = new Loader({


      apiKey: 'AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA',


      version: 'weekly',


      libraries: ['geometry', 'places'],


    });



    loader.load().then(() => {


      if (mapRef.current) {


        mapInstance.current = new window.google.maps.Map(mapRef.current, {


          center: { lat: 43.6532, lng: -79.3832 },


          zoom: 15,


          mapTypeId: 'roadmap',


        });



        if (coordinates) {


          createMarker(coordinates);


        }


      }


    });


  }, [coordinates]);



  const geocodeAddress = async (address, postalCode) => {

    try {

      // Append the postal code to the address for more specific geocoding

      const fullAddress = `${address}, ${postalCode}`;

      const response = await axios.get(

        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(

          fullAddress

        )}&key=AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA`

      );

  

      const { results } = response.data;

      if (results.length > 0) {

        const { lat, lng } = results[0].geometry.location;

        return { lat, lng };

      }

    } catch (error) {

      console.error('Error geocoding address:', address, postalCode, error);

    }

  

    return null;

  };

  



  const createMarker = (position) => {


    if (marker.current) {


      marker.current.setMap(null);


    }


    marker.current = new window.google.maps.Marker({


      position,


      map: mapInstance.current,


    });


    mapInstance.current.setCenter(position);


  };



  const openLightbox = (index) => {

    if (!isOpen) { // Ensure it only opens if not already open

      setPhotoIndex(index);

      setIsOpen(true);

    }

  };

  

  


  useEffect(() => {

    return () => {

      setIsOpen(false);  // Cleanup to ensure it's closed

      setPhotoIndex(0);  // Reset photo index on unmount

    };

  }, []);

  




  const handlePrev = (e) => {


    e.stopPropagation();


    setPhotoIndex((photoIndex + images.length - 1) % images.length);


  };



  const handleNext = (e) => {


    e.stopPropagation();


    setPhotoIndex((photoIndex + 1) % images.length);


  };



  const goBack = () => {


    if (isPopup && closePopup) {


      closePopup(); // Close the popup


    } else {


      navigate(-1); // Navigate back to the previous page


    }


  };



  const handleShareButtonClick = (e) => {


    e.preventDefault();


    const link = `${window.location.origin}/listing/${ml_num}/${encodeURIComponent(currentListing.address)}`;


    navigator.clipboard.writeText(link).then(() => {


      const shareLink = document.querySelector('.share-link span');


      shareLink.textContent = "Copied Listing Link to Clipboard!";



      // Temporarily change the text and revert back after 2 seconds


      setTimeout(() => {


        shareLink.textContent = "Share";


      }, 2000);


    });


  };



  const fetchGoogleReviews = async () => {


    try {


      const response = await fetch('/reviews-new.json'); // Fetch from local JSON file


      const data = await response.json();


      setReviews(data.result.reviews);


      setOverallRating(data.result.overall_rating); // Assuming overall rating is part of the response


    } catch (error) {


      console.error('Error fetching reviews:', error);


    }


  };



  useEffect(() => {


    fetchGoogleReviews();


  }, []);



  const swiperSettings = {


    slidesPerView: 1,


    loop: true,


    autoplay: {


      delay: 3000,


      disableOnInteraction: false,


    },


    modules: [Autoplay],


  };



  if (!listingData.currentListing) {


    return <div>Loading...</div>;


  }



  const { currentListing, historyListings } = listingData;



 // Update the images array to display only the first 7 images in the main gallery

const images = currentListing.photo_urls.length

? [currentListing.main_image, ...currentListing.photo_urls]

: [placeholderImage];


const galleryImages = images.slice(0, 7); // Only show first 7 images in the gallery




  const features = currentListing.property_features


    ? currentListing.property_features.split(',').filter((feature) => feature.trim() !== '')


    : [];



  const formattedSquareFeet = currentListing.square_feet && currentListing.square_feet !== "0" ? `Approximately ${currentListing.square_feet}` : "-";



  const listingInfoLeft = [


    { label: 'PIN#', value: currentListing.pin },


    { label: 'Taxes', value: currentListing.taxes ? `$${parseFloat(currentListing.taxes).toLocaleString()}` : '' },


    { label: 'Tax Year', value: currentListing.tax_year },


    { label: 'Status', value: currentListing.status === 'A' ? 'Available' : currentListing.status },


  ].filter(info => info.value);



  const listingInfoRight = [


    { label: 'Condo Registry Office', value: currentListing.condo_registry_office },


    { label: 'Condo Corp#', value: currentListing.condo_corp_num },


    { label: 'Property Mgmt Co', value: currentListing.property_mgmt_co },


    { label: 'Maintenance', value: currentListing.maintenance ? `$${parseFloat(currentListing.maintenance).toLocaleString()}` : '' },


  ].filter(info => info.value);



  const propertyInfoLeft = [


    { label: 'Level', value: currentListing.level },


    { label: 'Square Feet', value: formattedSquareFeet },


    { label: 'Unit #', value: currentListing.unit_num },


    { label: 'Dir/Cross St', value: currentListing.cross_streets },


    { label: 'Parking Drive Spaces', value: currentListing.parking_drive_spaces },


    { label: 'Total Parking Spaces', value: currentListing.total_parking_spaces },


    { label: 'A/C', value: currentListing.cooling },


    { label: 'Laundry Features', value: currentListing.laundry_features },


    { label: 'Area', value: currentListing.area },


    { label: 'Municipality', value: currentListing.municipality },


    { label: 'Community', value: currentListing.community },


  ].filter(info => info.value);



  const propertyInfoRight = [


    { label: 'Rooms', value: currentListing.rooms },


    { label: 'Bedrooms', value: currentListing.bedrooms },


    { label: 'Washrooms', value: currentListing.washrooms },


    { label: 'Kitchens', value: currentListing.kitchens },


    { label: 'Garage Type', value: currentListing.garage_type },


    { label: 'Apt/Unit', value: currentListing.unit_num },


    { label: 'Basement', value: currentListing.basement },


    { label: 'Heating Source', value: currentListing.heating_source },


    { label: 'Heating Type', value: currentListing.heating_type },


  ].filter(info => info.value);



  const roomInfo = [


    { type: currentListing.room_1, length: currentListing.room_1_length, width: currentListing.room_1_width },


    { type: currentListing.room_2, length: currentListing.room_2_length, width: currentListing.room_2_width },


    { type: currentListing.room_3, length: currentListing.room_3_length, width: currentListing.room_3_width },


    { type: currentListing.room_4, length: currentListing.room_4_length, width: currentListing.room_4_width },


    { type: currentListing.room_5, length: currentListing.room_5_length, width: currentListing.room_5_width },


    { type: currentListing.room_6, length: currentListing.room_6_length, width: currentListing.room_6_width },


    { type: currentListing.room_7, length: currentListing.room_7_length, width: currentListing.room_7_width },


    { type: currentListing.room_8, length: currentListing.room_8_length, width: currentListing.room_8_width },


    { type: currentListing.room_9, length: currentListing.room_9_length, width: currentListing.room_9_width }


  ].filter(room => room.type && (room.length || room.width));



  const washroomInfo = [


    { type: currentListing.washrooms_type_1, level: currentListing.washrooms_type_1_level, num_pcs: currentListing.washrooms_type_1_num_pcs },


    { type: currentListing.washrooms_type_2, level: currentListing.washrooms_type_2_level, num_pcs: currentListing.washrooms_type_2_num_pcs },


    { type: currentListing.washrooms_type_3, level: currentListing.washrooms_type_3_level, num_pcs: currentListing.washrooms_type_3_num_pcs },


    { type: currentListing.washrooms_type_4, level: currentListing.washrooms_type_4_level, num_pcs: currentListing.washrooms_type_4_num_pcs },


    { type: currentListing.washrooms_type_5, level: currentListing.washrooms_type_5_level, num_pcs: currentListing.washrooms_type_5_num_pcs }


  ].filter(washroom => washroom.type && washroom.level && washroom.num_pcs);



  const directionsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(currentListing.address)}`;



  const renderListingHistoryRow = (listing, index) => {

    const isCurrentListing = index === 0;

    const isSold = !!listing.sold_price; // Check if there is a sold_price value

    const statusText = isCurrentListing

      ? 'Currently Active'

      : isSold

      ? `Sold for $${parseFloat(listing.sold_price).toLocaleString()}`

      : 'Listing Removed';

  

    const rowClass = !user && !isCurrentListing ? 'blurred-row' : '';

  

    // Check if property_type is rental and adjust the price text

    const priceText = listing.property_type === 'Rental'

      ? `$${parseFloat(listing.price).toLocaleString()} (Lease Per Month)`

      : `$${parseFloat(listing.price).toLocaleString()}`;

  

    return (

      <tr key={listing.ml_num} className={rowClass}>

        <td>{listing.ml_num}</td>

        <td>{priceText}</td>

        <td>{statusText}</td>

      </tr>

    );

  };

  

  

  

  



  const MortgageCalculatorWithChart = ({ price }) => {


    const [salePrice, setSalePrice] = useState(price);


    const [downPayment, setDownPayment] = useState(price * 0.2);


    const [downPaymentPercent, setDownPaymentPercent] = useState(20);


    const [amortizationPeriod, setAmortizationPeriod] = useState(25);


    const [interestRate, setInterestRate] = useState(4.7);



    const calculateMonthlyPayment = () => {


      const principal = salePrice - downPayment;


      const monthlyRate = interestRate / 100 / 12;


      const numberOfPayments = amortizationPeriod * 12;


      return (


        principal *


        monthlyRate *


        Math.pow(1 + monthlyRate, numberOfPayments) /


        (Math.pow(1 + monthlyRate, numberOfPayments) - 1)


      ).toFixed(2);


    };



    const resetDefaults = () => {


      setSalePrice(price);


      setDownPayment(price * 0.2);


      setDownPaymentPercent(20);


      setAmortizationPeriod(25);


      setInterestRate(6.79);


    };



    useEffect(() => {


      setDownPayment((salePrice * downPaymentPercent) / 100);


    }, [salePrice, downPaymentPercent]);



    useEffect(() => {


      setDownPaymentPercent((downPayment / salePrice) * 100);


    }, [downPayment]);



    const data = {


      labels: ['Principal', 'Interest'],


      datasets: [


        {


          data: [salePrice - downPayment, downPayment],


          backgroundColor: ['#0075A4', '#72ADCF'],


          hoverBackgroundColor: ['#0075A4', '#72ADCF'],


        },


      ],


    };



    const options = {


      responsive: true,


      maintainAspectRatio: false,


      width: 375,


      height: 375,


    };



    return (


      <div className='mortgage-calculator-container'>


        <div className='mortgage-calculator'>


          <h3 className='section-heading'>Mortgage Calculator</h3>


          <div className='calculator-field'>


            <label>Sale Price: </label>


            <div className='input-with-icon'>


              <span>$</span>


              <input


                type='number'


                value={salePrice}


                onChange={(e) => setSalePrice(Number(e.target.value))}


                className='Inter-font'


              />


            </div>


          </div>


          <div className='calculator-field-inline'>


            <div className='calculator-field'>


              <label>Down Payment: </label>


              <div className='input-with-icon'>


                <span>$</span>


                <input


                  type='number'


                  value={downPayment}


                  onChange={(e) => setDownPayment(Number(e.target.value))}


                  className='Inter-font'


                />


              </div>


            </div>


            <div className='calculator-field'>


              <label>Down Payment (%): </label>


              <div className='input-with-icon'>


                <span>%</span>


                <input


                  type='number'


                  value={downPaymentPercent}


                  onChange={(e) => setDownPaymentPercent(Number(e.target.value))}


                  className='Inter-font'


                />


              </div>


            </div>


          </div>


          <div className='calculator-field'>


            <label>Amortization Period (years): </label>


            <div className='input-with-icon'>


              <span>Yrs</span>


              <input


                type='number'


                value={amortizationPeriod}


                onChange={(e) => setAmortizationPeriod(Number(e.target.value))}


                className='Inter-font'


              />


            </div>


          </div>


          <div className='calculator-field'>


            <label>Interest Rate (%): </label>


            <div className='input-with-icon'>


              <span>%</span>


              <input


                type='number'


                step='0.01'


                value={interestRate}


                onChange={(e) => setInterestRate(Number(e.target.value))}


                className='Inter-font'


              />


            </div>


          </div>


          <button onClick={resetDefaults} className='full-width-button Inter-font'>Reset</button>


        </div>


        <div className='mortgage-pie-chart'>


          <div style={{ width: '375px', height: '375px' }}>


            <Pie data={data} options={options} />


          </div>


          <div className='monthly-payment'>


            <h4 className='section-heading'>Monthly Payment: ${calculateMonthlyPayment()}</h4>


          </div>


          <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`} className='property-action-button'>


            Buy and get $5000 cash back


          </a>


        </div>


      </div>


    );


  };



  return (

    <div className='listing-page-container'>

      <div className='listing-detail-container'>

        <div className='listing-detail-content'>

        <div className='view-button'>

          {/* View All Images button with icon */}

          <button onClick={() => openLightbox(0)} className='view-images-button'>

            <FaImage style={{ marginRight: '8px'  }} /> {/* Adds the photo icon */}

            View All Images

          </button>

          </div>

          <div className='desktop-gallery'>

            <div className='grid-container'>

              <div className='hero-image image-wrapper' onClick={() => openLightbox(0)}>

                <img src={currentListing.main_image || placeholderImage} alt='Main Image' />

                <div className='image-overlay'></div>

              </div>

              

              {/* Display only the first 7 images */}

              {galleryImages.slice(1).map((url, index) => (

                <div className='small-image image-wrapper' key={index}>

                  <img

                    src={url || placeholderImage}

                    alt={`Gallery Image ${index + 1}`}

                    onClick={() => openLightbox(index + 1)}

                  />

                  <div className='image-overlay'></div>

                </div>

              ))}

            </div>

          </div>


          {isOpen && (

  <Lightbox

    key={`lightbox-${photoIndex}`} // Unique key for each render

    mainSrc={images[photoIndex]}

    nextSrc={images[(photoIndex + 1) % images.length]}

    prevSrc={images[(photoIndex + images.length - 1) % images.length]}

    onCloseRequest={() => {

      setIsOpen(false);

      setPhotoIndex(0); // Reset index on close

    }}

    onMovePrevRequest={() =>

      setPhotoIndex((photoIndex + images.length - 1) % images.length)

    }

    onMoveNextRequest={() =>

      setPhotoIndex((photoIndex + 1) % images.length)

    }

  />

)}








        <div className='mobile-gallery'>


          <div className='hero-image image-wrapper' onClick={() => openLightbox(0)}>


            <img src={currentListing.main_image || placeholderImage} alt='Main Image' />


            <div className='image-overlay'></div>


            <button className='image-nav prev' onClick={handlePrev}>&lt;</button>


            <button className='image-nav next' onClick={handleNext}>&gt;</button>


          </div>


        </div>



        {isOpen && (


          <Lightbox


            mainSrc={images[photoIndex]}


            nextSrc={images[(photoIndex + 1) % images.length]}


            prevSrc={images[(photoIndex + images.length - 1) % images.length]}


            onCloseRequest={() => setIsOpen(false)}


            onMovePrevRequest={() =>


              setPhotoIndex((photoIndex + images.length - 1) % images.length)


            }


            onMoveNextRequest={() =>


              setPhotoIndex((photoIndex + 1) % images.length)}


          />


        )}



        <div id="overview" className='address-container'>


          <h1 className='address-heading'>


            {currentListing.address}


            {currentListing.unit_num ? `, Unit ${currentListing.unit_num}` : ''}


          </h1>


          <div className='mls-number' style={{ color: 'grey', fontSize: '14px' }}>


            MLS#: {currentListing.ml_num}


          </div>


        </div>



        <div className='property-info' style={{ marginTop: '20px' }}>


          <div className='price'>


            {currentListing.property_type.toLowerCase() === 'rental'


              ? `$${parseFloat(currentListing.price).toLocaleString()}/month`


              : `$${parseFloat(currentListing.price).toLocaleString()}`}


          </div>


        </div>



        <div className='details'>


          <div className='info-item icon-text-container bedrooms'>


          <img

              src={bedroomIcon}

              alt='Bedrooms'

              className='icon'

            />


            <span className="with-words">{currentListing.bedrooms} Bedrooms</span>


            <span className="without-words">{currentListing.bedrooms}</span>


          </div>


          <div className='info-item icon-text-container bathrooms'>


          <img

              src={bathroomIcon}

              alt='Bathrooms'

              className='icon'

            />


            <span className="with-words">{currentListing.bathrooms} Bathrooms</span>


            <span className="without-words">{currentListing.bathrooms}</span>


          </div>


          <div className='info-item text-container'>


            <span>SQFT: {formattedSquareFeet}</span>


          </div>


        </div>



        <div className='property-info-separator'></div>



        <div className='main-content' style={{ display: 'flex', marginTop: '20px' }}>


          <div className='left-column' style={{ flex: '70%', backgroundColor: 'white', paddingTop: '0' }}>


            <h3 id="listing-history" className='section-heading'>Links</h3>


            <div className='link-buttons'>


              <a href={directionsUrl} className='property-action-button' target='_blank' rel='noopener noreferrer'>


                Directions


              </a>


              {currentListing.virtual_tour && (


                <a href={currentListing.virtual_tour} className='property-action-button' target='_blank' rel='noopener noreferrer'>


                  Virtual Tour


                </a>


              )}


            </div>



            <h3 className='section-heading'>Listing History</h3>

<table className='listing-history-table'>

  <thead>

    <tr>

      <th>MLS Number</th>

      <th>Listing Price</th>

      <th>Sold For</th>

    </tr>

  </thead>

  <tbody>

    {[currentListing, ...historyListings].map(renderListingHistoryRow)}

  </tbody>

</table>



  

{!user && historyListings.length > 0 && (

  <p>

    Login <span className='partners-text'><a href={`/login?redirect=${window.location.pathname}`}>here</a></span> with your existing account or Register <span className='partners-text'><a href={`/register?redirect=${window.location.pathname}`}>here</a></span> a new account to view listing history.

  </p>

)}



            {coordinates && (


              <>


                <h3 className='section-heading'>Nearby Area</h3>


                <div ref={mapRef} className='google-map'></div>


              </>


            )}



            <h3 id="facts-features" className='section-heading'>About This Property</h3>


            <p className='description'>{currentListing.ad_text}</p>



            {features.length > 0 && (


              <>


                <h3 className='section-heading'>Features</h3>


                <div className='grid-template-features'>


                  {features.map((feature, index) => (


                    <div className='feature' key={index}>


                      <p>{feature}</p>


                    </div>


                  ))}


                </div>


              </>


            )}



            {listingInfoLeft.length > 0 && listingInfoRight.length > 0 && (


              <>


                <h3 className='section-heading'>Listing Information</h3>


                <div className='info-table-container'>


                  <table className='info-table'>


                    <tbody>


                      {listingInfoLeft.map((info, index) => (


                        <tr key={index}>


                          <td><strong>{info.label}</strong></td>


                          <td>{info.value}</td>


                        </tr>


                      ))}


                    </tbody>


                  </table>


                  <table className='info-table'>


                    <tbody>


                      {listingInfoRight.map((info, index) => (


                        <tr key={index}>


                          <td><strong>{info.label}</strong></td>


                          <td>{info.value}</td>


                        </tr>


                      ))}


                    </tbody>


                  </table>


                </div>


              </>


            )}



            {propertyInfoLeft.length > 0 && propertyInfoRight.length > 0 && (


              <>


                <h3 className='section-heading'>Property Information</h3>


                <div className='info-table-container'>


                  <table className='info-table'>


                    <tbody>


                      {propertyInfoLeft.map((info, index) => (


                        <tr key={index}>


                          <td><strong>{info.label}</strong></td>


                          <td>{info.value}</td>


                        </tr>


                      ))}


                    </tbody>


                  </table>


                  <table className='info-table'>


                    <tbody>


                      {propertyInfoRight.map((info, index) => (


                        <tr key={index}>


                          <td><strong>{info.label}</strong></td>


                          <td>{info.value}</td>


                        </tr>


                      ))}


                    </tbody>


                  </table>


                </div>


              </>


            )}



            {roomInfo.length > 0 && (


              <>


                <h3 className='section-heading'>Room Information</h3>


                <table className='info-table'>


                  <thead>


                    <tr>


                      <th>Room Type</th>


                      <th>Length</th>


                      <th>Width</th>


                    </tr>


                  </thead>


                  <tbody>


                    {roomInfo.map((room, index) => (


                      <tr key={index}>


                        <td>{room.type}</td>


                        <td>{room.length} m</td>


                        <td>{room.width} m</td>


                      </tr>


                    ))}


                  </tbody>


                </table>


              </>


            )}



            {washroomInfo.length > 0 && (


              <>


                <h3 className='section-heading'>Washroom Information</h3>


                <table className='info-table'>


                  <thead>


                    <tr>


                      <th>Type</th>


                      <th>Level</th>


                      <th>Number of Pieces</th>


                    </tr>


                  </thead>


                  <tbody>


                    {washroomInfo.map((washroom, index) => (


                      <tr key={index}>


                        <td>{washroom.type}</td>


                        <td>{washroom.level}</td>


                        <td>{washroom.num_pcs}</td>


                      </tr>


                    ))}


                  </tbody>


                </table>


              </>


            )}



            {currentListing.extras && (


              <>


                <h3 className='section-heading'>Extras</h3>


                <p>{currentListing.extras}</p>


              </>


            )}



            <div id="payment-calculator">


              <MortgageCalculatorWithChart price={currentListing.price} />


            </div>


          </div>



          <div className='right-column' style={{ flex: '25%', marginLeft: '0px', backgroundColor: '#f0f0f0', paddingTop: '20px', position: 'sticky', top: '0', height: '100%' }}>


            {currentListing.realtor === 'MODERN SOLUTION REALTY INC.' && (


              <img src={logo} alt='Modern Solution Realty Inc.' style={{ marginBottom: '10px' }} />


            )}


            <p className='brokerage-info' style={{ fontWeight: currentListing.realtor === 'MODERN SOLUTION REALTY INC.' ? 'bold' : 'normal' }}>


              {currentListing.realtor}


            </p>


            <div className='contact-form-box'>


              <ContactForm defaultMessage={`I am interested in buying ${currentListing.address}`} />


            </div>


            <div className='property-action-box'>


              <p>Interested in this property? One of our agents would be happy to help you!</p>


              <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`} className='property-action-button'>


                Buy and get $5000 cash back


              </a>


            </div>


            <div className='property-action-box'>


              <p>Looking for more details about this property? Contact one of our agents and we will be happy to help.</p>


              <a href='tel:9058975000' className='property-action-button'>


                Contact Agent


              </a>


            </div>


            <video


              controls


              poster={videoThumbnail}


              width="100%"


            >


              <source src={videoFile} type="video/mp4" />


              Your browser does not support the video tag.


            </video>



            {/* Add custom Google Reviews here */}


            <div className='google-reviews-section'>


              <h3 className='google-reviews-heading'>Our Google Reviews</h3>


              <Swiper {...swiperSettings}>


                {reviews.map((review, index) => {


                  // Truncate review text if it exceeds 300 characters


                  const truncatedText = review.text.length > 300 


                    ? `${review.text.slice(0, 300)}...` 


                    : review.text;



                  return (


                    <SwiperSlide key={index}>


                      <div className='review-card'>


                        <div className='star-rating'>


                          {[...Array(5)].map((_, i) => (


                            <FaStar


                              key={i}


                              className='star'


                              color={i < review.rating ? '#9cdcf8' : '#e4e5e9'}


                            />


                          ))}


                        </div>


                        <p className='review-text'>


                          {`"${truncatedText}"`}


                        </p>


                        <p className='review-author'>- {review.author_name}</p>


                      </div>


                    </SwiperSlide>


                  );


                })}


              </Swiper>


            </div>


          </div>


        </div>



        <div className='bottom-bar'>


          <button className='action-button' style={{ backgroundColor: '#EF3E5E', color: 'white' }}>


            <a href={`/contact?property=${encodeURIComponent(currentListing.address)}`}>Buy and get $5000 Cash Back</a>


          </button>


          <button className='action-button' style={{ backgroundColor: '#398EDD', color: 'white' }}>


            <a href='/contact'>Contact Agent</a>


          </button>


        </div>


      </div>


    </div>

    </div>


  );


};



export default ListingDetail;


