import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../services/axios';
import './ContactForm.css';

const HiddenContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    municipality: '',
    message: '',
    agreeToPromotionalEmails: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!formData.email && !formData.phoneNumber) {
      setError('Please provide either an email or a phone number.');
      return;
    }

    setIsLoading(true);

    try {
      // Using the axios instance that should already be configured with the base URL
      const res = await axios.post('/api/sendEmailNoRecaptcha', formData);

      if (res.status === 200) {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          municipality: '',
          message: '',
          agreeToPromotionalEmails: false,
        });
        navigate('/thank-you');
      }
    } catch (err) {
      console.error('Form submission error:', err);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-container">
      <div className='contact-form'>
        {error && (
          <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="name-fields">
            <input
              type='text'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              placeholder='First Name'
              required
            />
            <input
              type='text'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              placeholder='Last Name'
              required
            />
          </div>
          <div className="contact-fields">
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder='Email'
            />
            <input
              type='tel'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder='Phone Number'
            />
          </div>
          <div>
            <input
              type='text'
              name='municipality'
              value={formData.municipality}
              onChange={handleChange}
              placeholder='Municipality'
            />
          </div>
          <div>
            <textarea
              name='message'
              value={formData.message}
              onChange={handleChange}
              placeholder="Tell us what you're looking for"
            ></textarea>
          </div>
          
          <button
            className='submit-button'
            type='submit'
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HiddenContactForm;