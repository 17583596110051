import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import ContactForm from './ContactForm'; // Ensure ContactForm component is imported
import './LandingPage-Selling.css';

const LandingPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const navigate = useNavigate();

  const handleConnectWithAgentClick = () => {
    setShowContactForm(true);
  };

  const handleCommissionInfoClick = () => {
    navigate('/commission-explained');
  };

  const handleSellingProcessClick = () => {
    navigate('/sell');
  };

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleBackClick = () => {
    setShowContactForm(false);
  };

  return (
    <div className="landing-page">
      <header className="landing-page-header">
        <h1 className="landing-page-title">Selling Your Home?</h1>
        <h2 className="landing-page-subtitle">We've sold over 2,100 properties in Ontario</h2>
      </header>
      
      <div className="landing-page-content">
        {!showContactForm ? (
          <>
            <button onClick={handleConnectWithAgentClick} className="landing-page-button">
              Connect me with an agent
            </button>
            <button onClick={handleCommissionInfoClick} className="landing-page-button">
              Learn about our commission structure
            </button>
            <button onClick={handleSellingProcessClick} className="landing-page-button">
              Learn about our selling process
            </button>
            <button onClick={handleContactClick} className="landing-page-button">
              Get a free home evaluation
            </button>
            <button onClick={() => window.location.href = 'tel:9058975000'} className="landing-page-button">
  Give us a call at 905-897-5000
</button>
          </>
        ) : (
          <>
            <ContactForm />
            <button onClick={handleBackClick} className="landing-page-button back-button-custom">
              Back
            </button>
          </>
        )}
      </div>

      {/* Icon Boxes */}
      <div className="icon-boxes-row">
        <div className="icon-box">
          <img src={icon1} alt="Icon 1" className="icon-box-image" />
          <h3 className="icon-box-heading">2100+ Properties Sold</h3>
        </div>
        
        <div className="icon-box">
          <img src={icon3} alt="Icon 3" className="icon-box-image" />
          <h3 className="icon-box-heading">$20M Sellers Saved on Commission</h3>
        </div>
        <div className="icon-box">
          <img src={icon4} alt="Icon 4" className="icon-box-image" />
          <h3 className="icon-box-heading">5/5 Stars - 224 Reviews</h3>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
