import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import './CityWidget.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


import torontoImage from '../assets/images/toronto.webp';

import bramptonImage from '../assets/images/brampton.webp';

import oakvilleImage from '../assets/images/oakville.webp';

import mississaugaImage from '../assets/images/mississauga.webp';

import ottawaImage from '../assets/images/ottawa.jpg';

import hamiltonImage from '../assets/images/hamilton.webp';

import londonImage from '../assets/images/london.webp';

import markhamImage from '../assets/images/markham.webp';

import vaughanImage from '../assets/images/vaughan.webp';

import ajaxImage from '../assets/images/Ajax.jfif';

import auroraImage from '../assets/images/Aurora.JPG';

import barrieImage from '../assets/images/Barrie.jpg';

import brockImage from '../assets/images/Brock.JPG';

import burlingtonImage from '../assets/images/Burlington.jpg';

import caledonImage from '../assets/images/Caledon.jpg';

import cambridgeImage from '../assets/images/Cambridge.jpg';

import claringtonImage from '../assets/images/Clarington.jpg';

import collingwoodImage from '../assets/images/Collingwood.jpg';

import erinImage from '../assets/images/Erin.jpg';

import georginaImage from '../assets/images/Georgina.jpg';

import grimsbyImage from '../assets/images/Grimsby.jpg';

import innisfilImage from '../assets/images/Innisfil.JPG';

import kawarthaLakesImage from '../assets/images/Kawartha Lakes.jfif';

import kingImage from '../assets/images/King.png';

import kingstonImage from '../assets/images/Kingston.JPG';

import lincolnImage from '../assets/images/Lincoln.jpg';

import midlandImage from '../assets/images/Midland.jpg';

import miltonImage from '../assets/images/Milton.jpg';

import newmarketImage from '../assets/images/Newmarket.jpg';

import niagaraFallsImage from '../assets/images/Niagara Falls.jpg';

import niagaraOnTheLakeImage from '../assets/images/Niagara-On-The-Lake.jpg';

import orangevilleImage from '../assets/images/Orangeville.jpg';

import orilliaImage from '../assets/images/Orillia.jfif';

import oshawaImage from '../assets/images/Oshawa.jfif';

import peterboroughImage from '../assets/images/Peterborough.jpg';

import pickeringImage from '../assets/images/Pickering.jpg';

import portColborneImage from '../assets/images/Port Colbourne.jpg';

import tinyImage from '../assets/images/Tiny.jfif';

import wasagaBeachImage from '../assets/images/Wasaga Beach.jpg';

import waterlooImage from '../assets/images/Waterloo.jfif';

import wellandImage from '../assets/images/Welland.jpg';

import whitbyImage from '../assets/images/Whitby.jpg';

import whitchurchImage from '../assets/images/Whitchurch.jpg';


const cities = [

  { name: 'Toronto', image: torontoImage },

  { name: 'Mississauga', image: mississaugaImage },

  { name: 'Brampton', image: bramptonImage },

  { name: 'Oakville', image: oakvilleImage },

  { name: 'Hamilton', image: hamiltonImage },

  { name: 'Vaughan', image: vaughanImage },

  { name: 'Waterloo', image: waterlooImage },

  { name: 'London', image: londonImage },

  { name: 'Markham', image: markhamImage },

  { name: 'Ajax', image: ajaxImage },

  { name: 'Aurora', image: auroraImage },

  { name: 'Barrie', image: barrieImage },

  { name: 'Brock', image: brockImage },

  { name: 'Burlington', image: burlingtonImage },

  { name: 'Caledon', image: caledonImage },

  { name: 'Cambridge', image: cambridgeImage },

  { name: 'Clarington', image: claringtonImage },

  { name: 'Collingwood', image: collingwoodImage },

  { name: 'Erin', image: erinImage },

  { name: 'Georgina', image: georginaImage },

  { name: 'Grimsby', image: grimsbyImage },

  { name: 'Innisfil', image: innisfilImage },

  { name: 'King', image: kingImage },

  { name: 'Kingston', image: kingstonImage },

  { name: 'Lincoln', image: lincolnImage },

  { name: 'Midland', image: midlandImage },

  { name: 'Ottawa', image: ottawaImage },

  { name: 'Milton', image: miltonImage },

  { name: 'Newmarket', image: newmarketImage },

  { name: 'Niagara-on-the-Lake', image: niagaraOnTheLakeImage },

  { name: 'Orangeville', image: orangevilleImage },

  { name: 'Orillia', image: orilliaImage },

  { name: 'Oshawa', image: oshawaImage },

  { name: 'Peterborough', image: peterboroughImage },

  { name: 'Pickering', image: pickeringImage },

  { name: 'Tiny', image: tinyImage },

  { name: 'Welland', image: wellandImage },

  { name: 'Whitby', image: whitbyImage },

  { name: 'Whitchurch', image: whitchurchImage },

];


const CityWidget = () => {

  const sliderRef = useRef(null);

  const [cityWidth, setCityWidth] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const updateCityWidth = () => {

    const cityElement = document.querySelector('.custom-city-column');

    if (cityElement) {

      const computedStyle = window.getComputedStyle(cityElement);

      const margin = parseFloat(computedStyle.marginLeft) + parseFloat(computedStyle.marginRight);

      const fullWidth = cityElement.offsetWidth + margin;

      setCityWidth(fullWidth);

    }

  };


  const smoothScroll = (direction) => {

    const duration = 500; // Duration of the scroll animation in ms

    const start = sliderRef.current.scrollLeft;

    const distance = direction === 'left' ? -cityWidth : cityWidth;

    let startTime = null;


    const step = (timestamp) => {

      if (!startTime) startTime = timestamp;

      const progress = Math.min((timestamp - startTime) / duration, 1);

      sliderRef.current.scrollLeft = start + distance * progress;


      if (progress < 1) {

        requestAnimationFrame(step);

      }

    };


    requestAnimationFrame(step);

  };


  const scrollLeft = () => {

    if (sliderRef.current) {

      sliderRef.current.scrollBy({

        left: -300,

        behavior: 'smooth',

      });

    }

  };


  const scrollRight = () => {

    if (sliderRef.current) {

      sliderRef.current.scrollBy({

        left: 300,

        behavior: 'smooth',

      });

    }

  };


  const handleResize = () => {

    const newWidth = window.innerWidth;

    if ((newWidth >= 768 && windowWidth < 768) || (newWidth < 768 && windowWidth >= 768)) {

      sliderRef.current.scrollTo({ left: 0, behavior: 'auto' });

    }

    setWindowWidth(newWidth);

    updateCityWidth();

  };


  useEffect(() => {

    updateCityWidth();

    window.addEventListener('resize', handleResize);

  

    return () => {

      window.removeEventListener('resize', handleResize);

    };

  }, []);

  


  return (

    <div className='custom-city-container'>

      <h2>Search Available Homes by City</h2>

      <div className='widget-container-city'>

  <button onClick={scrollLeft} className='slider-button left'>

    <FontAwesomeIcon icon={faChevronLeft} />

  </button>

  <button onClick={scrollRight} className='slider-button right'>

    <FontAwesomeIcon icon={faChevronRight} />

  </button>

  <div className='city-slider-wrapper'>

    <div className='city-slider' ref={sliderRef}>

      {cities.map((city, index) => (

        <Link

          to={`/listings?city=${city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase()}`}

          key={index}

          className='custom-city-column'

        >

          <div

            className='city-image-container'

            style={{ backgroundImage: `url(${city.image})` }}

          >

            <div className='city-name-overlay'>

              <span className='city-name'>{city.name}</span>

            </div>

          </div>

        </Link>

      ))}

    </div>

  </div>

</div>


    </div>

  );

};


export default CityWidget;

