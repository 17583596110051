import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './TopBar.css';
import AuthContext from '../context/AuthContext';
import usericon from '../assets/icons/user.svg';

const TopBar = () => {
  const { user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="top-bar">
      <div className="top-bar-container">
        <div className="spacer"></div>
        <div className="auth-links">
          {user ? (
            <>
              {/* For larger screens */}
              <div className="desktop-auth">
                <span className="auth-link">Logged in</span>
                <span className="auth-separator">|</span>
                <button onClick={logout} className="logout-button">Logout</button>
              </div>

              {/* For mobile screens */}
              <div className="mobile-auth">
                <div className="user-dropdown">
                  <img
                    src={usericon}
                    alt="User Icon"
                    className="user-icon"
                    onClick={toggleDropdown}
                  />
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item">Logged in</div>
                      <button onClick={logout} className="dropdown-item logout-button">Logout</button>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* For larger screens */}
              <div className="desktop-auth">
                <Link to="/login" className="auth-link">Login</Link>
                <span className="auth-separator">|</span>
                <Link to="/register" className="auth-link">Register</Link>
              </div>

              {/* For mobile screens */}
              <div className="mobile-auth">
                <div className="user-dropdown">
                  <img
                    src={usericon}
                    alt="User Icon"
                    className="user-icon"
                    onClick={toggleDropdown}
                  />
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <Link to="/login" className="dropdown-item">Login</Link>
                      <Link to="/register" className="dropdown-item">Register</Link>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;