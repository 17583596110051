import React, { useEffect, useState } from 'react';

const ListingModule = ({ listingNumbers }) => {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchListingData = async (listingNumber) => {
      console.log('Fetching data for listing number:', listingNumber);
      const url = `https://cors-anywhere.herokuapp.com/https://listings.modernsolution.ca/ListingDetails/${listingNumber}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const htmlContent = await response.text();
          console.log('HTML content fetched:', htmlContent);

          const data = {};

          const titleMatch = htmlContent.match(/<title>(.*?)<\/title>/i);
          data.title = titleMatch ? titleMatch[1] : 'Title not found';
          data.title = data.title.replace(/-/g, ' ');
          data.title = data.title.replace(/\b(?:For Rent|For Sale)\b,?\s*/i, '');

          const bedsMatch = htmlContent.match(/<span class="visible-xs-inline-block visible-sm-inline-block area-bedroom-bathroom-label">Beds<\/span> · ([^<,]*)/i);
          data.beds = bedsMatch ? bedsMatch[1] : '';

          const bathsMatch = htmlContent.match(/<span class="visible-xs-inline-block visible-sm-inline-block area-bedroom-bathroom-label">Baths<\/span> · ([^<,]*)/i);
          data.baths = bathsMatch ? bathsMatch[1] : '';

          const sqftMatch = htmlContent.match(/<span class="visible-xs-inline-block visible-sm-inline-block area-bedroom-bathroom-label">Sqft<\/span> · ([^<,]*)/i);
          data.sqft = sqftMatch ? sqftMatch[1] : '';

          const imageMatch = htmlContent.match(/<meta property="og:image" content="(.*?)" \/>/i);
          data.imageUrl = imageMatch ? imageMatch[1] : '';

          const addressMatch = htmlContent.match(/<span class="detail-address-street">(.*?)<\/span>/i);
          data.address = addressMatch ? addressMatch[1] : '';

          console.log('Extracted data:', data);
          return data;
        } else {
          console.error('Failed to fetch listing data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching listing data:', error);
      }
      return null;
    };

    const fetchListings = async () => {
      const listingDataPromises = listingNumbers.map((number) => fetchListingData(number));
      const fetchedListings = await Promise.all(listingDataPromises);
      setListings(fetchedListings.filter((data) => data !== null));
    };

    fetchListings();
  }, [listingNumbers]);

  return (
    <div className="custom-listing-container">
      {listings.map((listing, index) => (
        <div key={index} className="custom-listing-column">
          <a href={`https://listings.modernsolution.ca/ListingDetails/${listingNumbers[index]}`} target="_blank" rel="noopener noreferrer">
            <div className="image-container">
              {listing.imageUrl ? <img src={listing.imageUrl} alt="Listing" /> : <p>Image not available</p>}
            </div>
          </a>
          <p>
            {listing.beds && <span className="listing-info-button">Beds {listing.beds}</span>}
            {listing.baths && <span className="listing-info-button">Baths {listing.baths}</span>}
            {listing.sqft && <span className="listing-info-button">Sqft {listing.sqft}</span>}
          </p>
          <p>{listing.address}</p>
          <h2>{listing.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default ListingModule;
